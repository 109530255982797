// 이메일 정규식
import theme from "../styles/theme";

export const isEmail = email => {
    const emailRegex = /^(([^<>()\[\].,;:\s@"]+(\.[^<>()\[\].,;:\s@"]+)*)|(".+"))@(([^<>()[\].,;:\s@"]+\.)+[^<>()[\].,;:\s@"]{2,})$/i;
    return emailRegex.test(email);
}

export const getFileSize = fileSize => {
    let units = ['bytes', 'KB', 'MB', 'GB', 'TB', 'PB'];
    let index = Math.floor(Math.log(fileSize) / Math.log(1024));
    let size = (fileSize / Math.pow(1024, index)).toFixed(1);

    return `${size} ${units[index]}`;
}

export const getToday = () => {
    const weekDay = ['일', '월', '화', '수', '목', '금', '토'];
    const date = new Date();
    let year = date.getFullYear().toString();
    let month = date.getMonth() + 1;
    let day = date.getDate();
    let week = weekDay[date.getDay()];

    month = month >= 10 ? month : '0' + month;
    day = day >= 10 ? day : '0' + day;
    return `${year}.${month}.${day} (${week})`;
}

export const maskingName = strName => {
    if (strName.length > 2) {
        let originName = strName.split('');
        originName.forEach((name, i) => {
            if (i === 0 || i === originName.length - 1) return;
            if (strName.length === 3) {
                originName[i] = ' * ';
            } else {
                originName[i] = '*';
            }
        });
        let joinName = originName.join();
        return joinName.replace(/,/g, '');
    } else {
        let pattern = /.$/; // 정규식
        return strName.replace(pattern, ' *');
    }
}

export const colorChange = (color, isUsing) => {
    if (isUsing) {
        return theme.colors.blackColor;
    }
    switch (color) {
        case '핑크색':
            return theme.colors.activePink;
        case '주황색':
            return theme.colors.activeLightOrange;
        case '노랑색':
            return theme.colors.activeYellow;
        case '초록색':
            return theme.colors.activeGreen;
        case '연두색':
            return theme.colors.activeYellowGreen;
        case '회색':
            return theme.colors.activeGray;
    }
}

export const getSpeech = (patientName, clinic) => {
    let voices = [];

    //디바이스에 내장된 voice 를 가져온다.
    const setVoiceList = () => voices = window.speechSynthesis.getVoices();
    setVoiceList();

    if (window.speechSynthesis.onvoiceschanged !== undefined) {
        //voice list 에 변경됐을때, voice 를 다시 가져온다.
        window.speechSynthesis.onvoiceschanged = setVoiceList;
    }

    const speech = (text) => {
        const language = 'ko-KR';
        const utterThis = new SpeechSynthesisUtterance(text);

        utterThis.rate = 0.88;
        utterThis.volume = 1;
        utterThis.lang = language;

        /*
            한국어 voice 찾기
            디바이스 별로 한국어는 ko-KR 또는 ko_KR로 voice 가 정의되어 있다.
        */
        const kor_voice = voices.find(elem => (elem.lang === language) || (elem.lang === language.replace('-', '_')));

        // 힌국어 voice 가 있다면 ? utterance 에 목소리를 설정한다 : 리턴하여 목소리가 나오지 않도록 한다.
        if (kor_voice) {
            utterThis.voice = kor_voice;
        } else {
            utterThis.voice = voices[0];
            // return;
        }

        // utterance 를 재생(speak)한다.
        window.speechSynthesis.speak(utterThis);
    };

    if (patientName || clinic) {
        speech(`${patientName}님 ${clinic}로 오시기 바랍니다.`);
    }
};

export const getSpeech2 = (patientName, clinic) => {
    const script = document.createElement('script');
    script.src = "https://code.responsivevoice.org/responsivevoice.js?key=YOUR_KEY"; // key=YOUR_KEY를 그대로 두어도 됩니다.
    script.async = true;
    document.body.appendChild(script);

    
};
